/* You can add global styles to this file, and also import other style files */
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeng/resources/themes/lara-light-blue/theme.css';
@import '../node_modules/primeicons/primeicons.css';

.page-background {
    background-color: #263148;
}
.heading-background {
    background-color: #2F497D;
}

// primeng table
table {
    border-collapse: separate;
    border-spacing: 0;
}
.p-datatable-wrapper {
    height: calc(100vh - 260px);
    border: 1px solid #adadad;
    border-bottom: unset;
}
.p-datatable-table .p-datatable-thead > tr > th {
    background-color: #3861b2;
    color: white;
    border: 1px solid #adadad;
    position: sticky;
    top: -1px;
}

.p-datatable-table .p-datatable-tbody > tr > td {
    background-color: #263148;
    color: white;
    border: 1px solid #adadad;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.p-component .p-paginator .p-paginator-bottom {
    background-color: #263148;
    color: white;
}

// primeng dropdown
.p-dropdown {
    width: 100% !important;
}

.p-multiselect {
    width: 100% !important;
}

.p-editable-column {
    .p-dropdown {
        height: 40px !important;
        .p-inputtext {
            padding: 0.40rem;
        }
    }
}

.p-inputtext {
    width: 100% !important;
}

// Calender 
.end-date-calender {
    .p-datepicker {
        left: -180px !important
    }
}

// Cursor
.cursor-pointer {
    cursor: pointer;
}

// Menu
.p-menu {
    padding: unset !important;
    .p-menu-list {
        margin-bottom: 5px;
        margin-top: 5px;
    }
    a {
        color: unset;
        text-decoration: unset;
    }
}

// Notification Panel
.notification-panel {
    background-color: #2F497D;
    height: 90%;
    width: 20%;
    position: absolute;
    z-index: 999999;
    right: 0;
    top: 60px;
    padding: 10px;
    overflow-y: auto;
}


// radio button
.radio-button {
    border: 1px solid black;
}

.cursor-hand {
    cursor: pointer;
}
.btn-none {
    color: black;
    background-color: white;
    border-color: black;
}
.btn-none:hover {
    color: black;
    background-color: white;
    border-color: black;
}

.dropdown-item:hover {
    background-color: #3861b2;
    color: white;
    cursor: pointer;
}

.dropdown-menu {
    transform: translate3d(1.556px, 40px, 0px) !important;
    width: 100% !important;
}
.btn {
    text-align: left !important;
}
.btn-primary {
    background-color: #3861b2 !important;
}

.modal-body {
    height: calc(100vh - 225px);
    overflow-x: auto;
}

.checkbox-codes {
    height: 15vh;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
}

.date-picker {
    border-radius: 0.375rem !important;
    border: 1px solid #adadad !important;
    width: 100%;
    height: 40px;
}

.required {
    color: red;
}

.btn-middle {
    display: block;
    margin: 0 auto;
}

.font-weight-bold {
    font-weight: bold;
}

ul {
    padding-left: unset !important;
}

